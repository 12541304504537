/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  grid-row: 1;
  grid-column: 1;
  background-color: var(--bg-color-dark1);
  z-index: 100;
}
