/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.6em 0em 0.6em 0.7em;
  gap: 1em;
}

.title-icon {
  flex-shrink: 0;
}

.meta-container {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}

.meta-container-title {
  font-size: large;
}

.meta-container-subtitle {
  /* font-size: small; */
}
