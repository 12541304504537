.container {
  display: flex;
  flex-direction: column;

  max-width: 80%;

  gap: 15px;
  width: 100%;
  height: 100%;
}
