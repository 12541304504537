/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.unit-input {
  padding-left: 20%;
  padding-right: 20%;
  display: flex;
  flex-direction: column;
}
.unit-input-line {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0 1em 2em 1em;
  gap: 1em;
}
.unit-default-title {
  color: var(--fg-color-bright);
  font-weight: bold;
  margin: 0 1em 0 auto;
}
.unit-default-field {
  width: 30%;
  color: var(--fg-color-bright);
  background-color: transparent;
  font-size: medium;
  border-style: none;
  text-align: right;
  padding: 0.5em;
}
.unit-input-field {
  width: 30%;
  color: var(--fg-color-frame);
  background-color: transparent;
  font-size: medium;
  border-color: var(--fg-color-frame);
  border-style: solid;
  border-width: var(--outline-thickness-thin);
  border-radius: var(--radius-corner-std);
  text-align: right;
  padding: 0.5em;
}
.unit-input-field:focus {
  outline: none;
}
.unit-input-invalid {
  color: var(--fg-color-warning);
}
