.container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.icon-container {
  display: flex;
  margin-right: 6px;
  flex-shrink: 0;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  /*white-space: nowrap;*/
}

.empty {
  width: 3px;
  height: 10px;
  color: #999;
}
