.container {
  display: flex;
  align-items: center;
  padding-left: 15px;
  justify-content: space-between;
}

.font-bold-space {
  font-weight: bold;
}

.header {
  display: flex;
  flex-direction: row;
  font-size: large;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0px 10px;
}

.date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1px;
  color: var(--fg-color-greyed);
}

.box-number-2 {
  width: 18px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
