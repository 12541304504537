.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.device {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    border: 1px solid #303859;
    background-color: #121930;
    border-radius: 6px;
    gap: 20px;
}

.device-generic {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #FFFFFF;
    align-items: normal;
}

.devices-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.device-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.title {
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
}

.sub-title {
    color: var(--fg-color-greyed);
    font-size: 12px;
}

.infos {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 8px;
}

.gray {
    display: flex;
    fill: var(--fg-color-greyed);
}

.status-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.name-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.reconnect-device {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-items: center;
    font-size: 12px;
    color: rgba(255,192,0,0.8);
}
