/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.license-container {
  font-size: small;
  overflow: auto;
  padding: 5pt;
}

.license-heading {
  font-weight: bold;
  font-style: italic;
}

.license-name {
  margin-top: 1em;
  font-weight: bold;
  font-style: italic;
}

.license-applications ul {
  padding-left: 1em;
  margin: 0em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.license-text {
  white-space: pre;
  font-size: smaller;
}
