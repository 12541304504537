.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 2px solid red;
  border-radius: 30px;
  background-color: #272822;
  padding: 20px;
  gap: 20px;
}
