.attachments {
  grid-area: attachments;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 8px;
  background-color: var(--bg-color-dark1);
  overflow-y: hidden;
  overflow-x: scroll;
  max-height: 100px;
  min-height: 0
}

.attachment-item {
  object-fit: cover;
  border-radius: 0.5em;
  height: 80px;
  width: 80px;
}

.attachment-item-wrapper {
  height: 80px;
  width: 80px;
  position: relative;
}

.attachment-item-close-container {
  position: absolute;
  top: -5px;
  right: -5px;
  border: 1px solid var(--bg-color-frame);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--bg-color-dark1);
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
