.container {
  display: flex;
  flex-direction: column;
  background: rgba(18, 25, 48, 1);
  border: 1px solid rgba(48, 56, 89, 1);
  border-radius: 6px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.gradient {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: radial-gradient(
      600px 200% at 100% 187.45%,
      rgba(28, 173, 228, 0.3) 0%,
      rgba(28, 173, 228, 0) 100%
    )
    #090f23;
  z-index: 1;
}
