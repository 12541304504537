/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.tag-nursing-complexity {
  height: 16px;
  display: inline-block;
  vertical-align: baseline;
  position: relative;
  top: 2px;
}
