.container {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
  margin-right: 4px;
}

.container-element {
  height: 100%;
  display: flex;
}
