.container {
  position: absolute;
  width: 0;
  height: 0;
  right: 2px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--blue-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 170;
  flex-shrink: 0;
}

.label {
  right: 2px;
  transform: translate(7px, 0px);
  font-size: 8px;
  font-weight: 700;
  flex-shrink: 0;
}
