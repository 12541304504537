/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.list-container {
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  padding: 0.6em 16px 0.6em 16px;
  overflow-y: auto;
}

.hint {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex-grow: 1;
}
.hint-text {
  color: var(--fg-color-greyed);
  text-align: center;
  flex-grow: 0;
}
