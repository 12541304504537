/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.line {
  border-left-width: var(--outline-thickness-std);
  border-left-style: solid;
  width: var(--outline-thickness-std);
}
