/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.readconfirmation-status {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}
.readconfirmation-none {
  border: dotted var(--fg-color-greyed) 1pt;
}
.readconfirmation-implicit-partial {
  border: solid var(--fg-color-greyed) 1pt;
}
.readconfirmation-implicit {
  background-color: var(--fg-color-greyed);
}
.readconfirmation-explicit-partial {
  border: solid var(--fg-color-frame) 1pt;
}
.readconfirmation-explicit {
  background-color: var(--fg-color-frame);
}
