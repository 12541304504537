.container {
  display: flex;
  flex-direction: column;
  padding: 0 1.2em;
  gap: 1.5em;
}

.paragraph-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.title-paragraph {
  display: flex;
  flex-direction: column;
}

.list-item {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: large;
  font-weight: bold;
  color: var(--fg-color-frame);
  margin-bottom: 10px;
}

.divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
}

.confirm-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1.2em;
  background-color: var(--bg-color-dark1-brighter);
  border-left: 2px solid var(--fg-color-frame);
}

.confirm-title {
  font-size: large;
  font-weight: bolder;
  color: var(--fg-color-frame);
}

.confirmActive {
  font-size: small;
  color: var(--fg-color-greyed);
}
.confirmDisabled {
  font-size: small;
  color: var(--fg-color-greyed);
}

.confirm-button {
  width: 75%;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
}

.forensic {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: normal;
  gap: 0.3em;
  color: var(--fg-color-greyed);
  margin-bottom: 1em;
}

.undo {
  width: 32px;
  height: 32px;
  fill: var(--fg-color-frame);
  rotate: -45deg;
}

.subtitute-take-photo-container {
  border: 0.5px solid var(--fg-color-greyed);
  padding: 20px;
  font-size: large;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.camera-icon {
  fill: var(--fg-color-frame);
}

.created-image-container {
  flex-shrink: 0;
}
.created-image {
  width: 48px;
  height: 48px;
}

.uploaded-image-container {
  margin: 12px 24px;
  max-height: none;
}
.uploaded-image-wrapper {
  flex-shrink: 0;
  width: auto;
  height: auto;
}
.uploaded-image {
  flex-shrink: 0;
  object-fit: none;
  width: 280px;
  height: auto;
}

.substitute-paragraph-container {
  display: block;
  position: sticky;
  top: -1.2em;
  z-index: 1;
  padding: 0.5em 20px 0 20px;
  background-color: var(--bg-color-dark1);
}
.substitute-paragraph-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.substitute-paragraph-text-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.substitute-paragraph-text {
  font-size: normal;
  font-weight: bold;
  margin-right: 0.5em;
  background-color: var(--bg-color-dark1);
}
.substitute-paragraph-completed-text {
  color: var(--fg-color-frame);
  font-size: normal;
  font-weight: bold;
  margin-right: 0.5em;
  background-color: var(--bg-color-dark1);
}
.more-button {
  fill: var(--fg-color-greyed);
  stroke: var(--fg-color-greyed);
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}
.less-button {
  fill: #ffffffb0;
  stroke: #ffffffb0;
  width: 48px;
  height: 48 px;
  flex-shrink: 0;
}
.shadow-bottom {
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  content: '';
  height: 1em;
  background-image: linear-gradient(
    to top,
    rgba(var(--bg-color-dark1-rgb), 0),
    rgba(var(--bg-color-dark1-rgb), 1) 100%
  );
}
.comment-button {
  flex-shrink: 0;
  background-color: transparent;
  border: none;
  transform: translateY(3px);
}
.comment-icon {
  fill: var(--fg-color-frame);
}
.comment-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0px 24px 24px 24px;
  color: var(--fg-color-greyed);
}
.comment-inner-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}
.comment-text {
  color: var(--fg-color-bright);
}

.checked-icon {
  fill: var(--fg-color-bright);
  stroke: var(--fg-color-bright);
}
