/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.2em;
  cursor: pointer;
}
.container {
  padding: 0.5em;
  border-radius: var(--radius-corner-std);
}
.highlight {
  color: var(--fg-color-frame);
}
.challenge {
  color: var(--fg-color-warning);
}
.request-source {
  color: var(--fg-color-bright2);
  margin-left: 0.2em;
}
.request-source::before {
  content: '(';
}
.request-source::after {
  content: ')';
}
