/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.countdown-expired {
  color: var(--fg-color-warning);
}
