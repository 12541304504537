.container {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: repeating-linear-gradient(
    -45deg,
    rgba(18, 23, 46, 0.6),
    rgba(18, 23, 46, 0.6) 10px,
    rgba(48, 56, 89, 0.6) 10px,
    rgba(48, 56, 89, 0.6) 20px
  );
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}

.description {
  font-size: var(--small-text);
  color: var(--fg-color-greyed);
}

.description-alone {
  font-size: var(--medium-text);
  font-weight: var(--text-weight);
  color: #d0d0d0;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
  font-size: var(--large-text);
  font-weight: var(--text-weight);
  color: #e0e0e0;
}
