/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.main-info-container {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.main-info-container .thread-item-message {
  background: none;
}
