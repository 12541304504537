/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.main-button {
  text-align: center;
  justify-content: center;
  width: auto;
  height: 100%;
}
