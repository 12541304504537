/* Container styles */
.container-grid {
  display: grid;
  grid-gap: 1rem; /* Gap between rows and columns */
}

.container-flexbox {
  display: flex;
  flex-direction: column;
  gap: 2rem; /* Gap between rows */
}

/* Row container styles */
.row-container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 1rem; /* Gap between items */
}

.row-container-flexbox {
  display: flex;
  flex-direction: row;
  gap: 2rem; /* Gap between items */
}

/* Item container */
.item-container {
  display: flex;
  /*width: 100%;*/
  /*flex-grow: 1;*/
}

/* Yellow background container */
.yellow-background-container {
  /*width: 100%;*/
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-rows: 1fr;
  gap: 1rem;
}

/* Navigation container */
.navigation-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Fallback container */
.fallback-container {
  /* Add any desired styles for the fallback */
}

.error-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 2px solid red;
  border-radius: 30px;
}
