.container {
  display: flex;
  flex-direction: row;
  background-color: #121930;
  border: 1px solid #303859;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.container-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.next-bed-wrapper {
  position: relative;
}

.content {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 8px 7px;
  gap: 7px;
  width: 100%;
}

.empty {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  width: 100%;
  position: relative;
  padding: 7px;
  cursor: pointer;
  /*overflow: hidden;*/
  gap: 7px;
}

.infos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 10px;
  flex: 1;
}

.room-index {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  top: 1px;
  right: 5px;
  font-size: 10px;
  color: rgba(139, 140, 147, 1);
  /*background-color: #121930;*/
}

.bubble {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #f9c80e;
}
