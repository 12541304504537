/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: row;
  gap: 0.4em;
  align-items: center;
}
.icon {
  fill: var(--fg-color-bright);
  flex-shrink: 0;
}
.description {
  font-size: medium;
  cursor: default;
}
