.button-x-btn {
  padding: 4px;
  height: 100%;
  width: 100%;
}

.button-x {
  box-sizing: border-box;

  cursor: pointer;
  float: right;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px;
  height: 100%;
  width: 100%;
}

/* Style the hamburger menu - Part 1: middle line*/
.button-x-line {
  background: transparent;
  display: block;
  height: 10%;
  position: relative;
  width: 100%;
}

/* Style the hamburger menu - Part 2: add a line before and after*/
.button-x-line::before,
.button-x-line::after {
  background: var(--fg-color-frame);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.button-x-line::before {
  transform: rotate(-45deg);
  top: 0;
}
.button-x-line::after {
  transform: rotate(45deg);
  top: 0;
}
