.button-container {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview-image-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0);
}

.preview-image-container img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}
