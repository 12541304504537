/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.6em 0.7em 0.6em 0.7em;
  gap: 1em;
}

.icon {
  flex-shrink: 0;
}

.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}
.main-item {
  /* font-size: large; */
}
.sub-item {
  font-size: small;
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: space-between;
  color: var(--fg-color-bright2);
}

.timestamp {
  text-align: right;
  font-style: italic;
}
.count {
  font-style: italic;
}
