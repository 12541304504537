/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  height: 100%;
  overflow-y: auto;
}

.content-container {
  min-height: calc(100% - 2 * 1.5em);
  display: flex;
  margin: 1.5em;
  flex-direction: column;
}
.top-spacer {
  flex: 2;
}
.user {
  margin-left: 20%;
  font-size: x-large;
}
.central-container {
  border-bottom: 1px solid var(--fg-color-greyed-dark);
  display: grid;
  grid-template-columns: 20% 1fr;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  cursor: pointer;
}

.central-container-no-border {
  display: grid;
  grid-template-columns: 20% 1fr;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
}

.central-icon {
  margin-left: auto;
  margin-right: 0.8em;
  transform: scale(0.8);
  fill: var(--fg-color-bright);
  width: 40px;
  height: 40px;
}
.central-text {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16pt;
  color: var(--fg-color-frame);
}

.central-white-text {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16pt;
  color: var(--fg-color-bright);
}

.log-out {
  padding-top: 0.7em;
  padding-left: 20%;
  font-size: 16pt;
  color: var(--fg-color-frame);
}
.middle-spacer {
  flex: 9;
  margin-top: 20px;
  margin-bottom: 20px;
}
.support {
  font-size: large;
  padding: 2em 0 1em 20%;
}
.data-protection {
  font-size: large;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.bottom-spacer {
  flex: 1;
}
