:root {
  --pl: 12px;
}

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--fg-color-dark1);
  z-index: 1;
}

.header-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 6px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: large;
  font-weight: 600;
  margin-left: var(--pl);
  padding: 0;
}

.content-actions {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  padding-right: var(--pl);
  padding-left: var(--pl);
  gap: 12px;
}

.partner-operations-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.participants-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
}

.participant {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.participants-list {
  margin-top: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  padding-left: var(--pl);
  padding-right: var(--pl);
  font-size: 1rem;
  overflow-y: scroll;
}

.selection-control {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background-color: var(--fg-color-dark1);
}

.remove-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 6px;
}

.add-button-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding-left: var(--pl);
  padding-right: var(--pl);
}

.actions-bottom-container {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  flex-direction: column;
  padding-left: var(--pl);
  padding-right: var(--pl);
  padding-bottom: 20px;
  padding-top: 20px;
}

.participant-name {
  display: flex;
  flex-direction: column;
}

.role {
  font-size: smaller;
  color: var(--fg-color-greyed);
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines you want to display */
  -webkit-box-orient: vertical;
  flex: 1;
  /* Adjust the height as needed, based on font size and line height */
}

.icon-role-container {
  background-color: var(--bg-color-dark2);
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.icon-role {
  border-radius: 24px;
  fill: white;
  width: 45px;
  height: 45px;
  position: relative;
  top: 4px;
  left: 2px;
}

.icon {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  padding: 2px;
  background-color: var(--bg-color-dark2);
  fill: white;
}

.no-shrink {
  flex-shrink: 0;
}
