/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  z-index: 100;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-container {
  display: flex;
  flex-grow: 1;
  background-color: black;
  flex-direction: column;
  position: relative;
}

.overlay-top {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 102;
}

.overlay-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.capture-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 95px;
  border-radius: 100px;
  border: 4px solid white;
}

.capture-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 100px;
}

.loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 101;
}

.photo-shoot {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
  opacity: 0.5;
  z-index: 101;
}

.error-screen {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  top: 0;
  left: 0;
}

.error-screen-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.error-screen-message {
  font-size: 1em;
  margin-bottom: 10px;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.capture-actions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-state {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
