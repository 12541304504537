/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.canvas-rect {
  position: absolute;
  top: 0;
  left: 0;
}
