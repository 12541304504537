/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  height: 2em;
  min-width: 2em;
  border-style: solid;
  border-radius: 1em;
  border-width: var(--outline-thickness-thin);
  border-color: var(--fg-color-frame);
  display: flex;
  justify-content: center;
  align-items: center;
}

.checked-container {
  height: 2em;
  min-width: 2em;
  border-radius: 1em;
  background-color: var(--fg-color-frame);
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled-container {
  height: 2em;
  min-width: 2em;
  border-style: solid;
  border-radius: 1em;
  border-width: var(--outline-thickness-thin);
  border-color: var(--fg-color-greyed);
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  font-size: medium;
  cursor: default;
}

.checked-label {
  font-size: medium;
  font-weight: bold;
  cursor: default;
}
