.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 20px;
  z-index: 2;
}

.stars {
  position: absolute;
  width: 700px;
  right: -200px;
  top: 50px;
  z-index: 2;
}

.text {
  max-width: 600px;
}

.gradient {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  background: radial-gradient(
      600px 200% at 100% 187.45%,
      rgba(28, 173, 228, 0.3) 0%,
      rgba(28, 173, 228, 0) 100%
    )
    #090f23;

  z-index: 1;
}
