.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4em;
}
.icon {
  fill: var(--fg-color-bright);
  flex-shrink: 0;
}
.description {
  font-size: medium;
  cursor: default;
}
