.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px solid #242a42;
  border-radius: 8px;
  background-color: #121830;
}

.input {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  padding-left: 0;
  font-size: 1rem;
  outline: none;
  border: none;
  background-color: transparent;
  color: white;
}

.icon {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
