.thread {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.thread-item-same-sender {
  margin-top: -0.2em;
}

.spacer {
  flex: 1;
}

.last-element {
  font-size: 2pt;
}

.load-more {
  margin: 0.5em;
  margin-left: 2em;
  margin-right: 2em;
  text-align: center;

  background-color: var(--bg-color-dark2);
  border-radius: var(--radius-corner-std);
}
