/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding: 0.5em 0 0.5em 0;
}

.button {
  width: 75%;
  margin: 1.5em 0 0.5em 0;
}
