/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000000c0;
}
