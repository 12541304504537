/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  text-align: center;

  vertical-align: middle;
  overflow: hidden;

  display: flex;
  align-items: center;
}
.container span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.missing-location {
  color: var(--fg-color-warning);
}
