/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.line {
  border-top-width: var(--outline-thickness-std);
  border-top-style: solid;
  height: var(--outline-thickness-std);
}
