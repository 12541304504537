/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.description-header {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  gap: 0.5em;
}
.description-header-aligntop {
  align-items: flex-start;
}
.description-icon {
  fill: var(--fg-color-bright);
  flex-shrink: 0;
  height: 24px;
  width: 24px;
}
.team-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2em;
}
.chat-participant-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2em;
  flex-grow: 1;
}
.chat-participants {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.team-button-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.3em;
}
.team-button {
  height: 30px;
  width: 35px;
  padding: 0px;
  background: none;
}
.team-button svg {
  fill: var(--fg-color-frame);
}

.team-list-overlay {
  padding: 0em 1.5em 0em 1.5em;

  transition-duration: 0.3s;
  transition-property: max-height, padding-bottom;
  overflow: hidden;

  background: rgba(var(--bg-color-dark1-rgb), 0.8);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.team-list-overlay-visible {
  padding-bottom: 1em;
  max-height: 100%;
}
.team-list-overlay-hidden {
  max-height: 0;
}
