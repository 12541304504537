.container {
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 2px;
  border-radius: 2px;
  font-size: 9px;
  padding: 2px 4px;
  white-space: nowrap;
  color: rgb(255, 255, 255);
  background-color: rgba(139, 140, 147, 0.1);
}
