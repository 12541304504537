/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 0.5em;
}

.column-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.3rem;
}

.status {
  flex-grow: 0;
}
.names {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
}
.role {
  font-size: smaller;
  color: var(--fg-color-frame);
  word-wrap: break-word;
}

.gray-name {
  color: var(--fg-color-greyed);
}

.buttons {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.3em;
}

.button {
  height: 30px;
  width: 30px;
  aspect-ratio: 1;
  padding: 3px;
  background: none;
  position: relative;
  cursor: pointer;
}
.button svg {
  height: 30px;
  width: 30px;
  fill: var(--fg-color-frame);
  position: absolute;
}
.button div {
  color: var(--fg-color-frame);
  position: absolute;
  top: 0;
  right: 0;
}

.online-status {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}
.status-online {
  background-color: var(--fg-color-frame);
  border: solid var(--fg-color-frame) 1pt;
}
.status-offline {
  border: solid var(--fg-color-greyed) 1pt;
}
.status-away {
  border: solid var(--fg-color-frame) 1pt;
}

.phone-number-call-button {
  border: solid var(--fg-color-frame) 1pt;
  border-radius: 50px;
  padding: 0.3em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  color: var(--fg-color-frame);
  width: 100%;
}

.phone-numbers-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.5em;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em;
  margin-left: 10px;
}
