/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.bottombar {
  width: 75%;
  padding: 2em;
}
