/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.text {
  margin-left: 1.2em;
  margin-top: 0.4em;
  margin-bottom: 0.4em;
  color: var(--fg-color-frame);
}
.add-text-container {
  display: flex;
  flex-direction: column;
}
.comment-text {
  margin-left: 1.2em;
  margin-bottom: 0.2em;
  color: var(--fg-color-greyed);
}
.changes-text {
  margin-left: 1.2em;
  margin-bottom: 0.6em;
  color: var(--fg-color-warning);
}

.icon-container {
  flex-shrink: 0;
  width: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.icon {
  stroke: var(--fg-color-frame);
  fill: var(--fg-color-frame);
}

.line {
  flex: 1;
  border-left-color: var(--fg-color-frame);
}
