/*
 * Replace this with your own classes
 *
 * e.g.
 */

.container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  background-color: var(--bg-color-dark1);

  padding: 0.2em;
  padding-left: var(--padding-std);
  padding-right: 0;

  border: none;

  width: 100%;

  position: relative;
}

.info-container-w-loc {
  grid-area: logo;
  width: 100%;

  display: grid;
  grid-template-columns: 30px auto;
  grid-template-rows: 30px 30px;
  grid-template-areas:
    'user-icon user-name'
    'loc-icon loc-value';

  gap: 0 5px;
  font-size: medium;
}

.info-container-wo-loc {
  grid-area: logo;
  width: 100%;

  display: grid;
  grid-template-columns: 36px auto;
  grid-template-rows: 36px;
  grid-template-areas: 'user-icon user-name';
  gap: 0 5px;

  font-size: large;
}

.icon-user {
  grid-area: user-icon;
  fill: var(--fg-color-bright);
  height: 100%;
  width: 100%;
  padding: 10%;
  aspect-ratio: 1;
}
.icon-location {
  grid-area: loc-icon;
  fill: var(--fg-color-bright);
  height: 100%;
  width: 100%;
  padding: 10%;
  aspect-ratio: 1;
}
.info-user {
  height: 100%;
  width: 100%;
  grid-area: user-name;
}
.info-location {
  height: 100%;
  width: 100%;
  grid-area: loc-value;
}

.menu-button-container {
  grid-area: menu;

  display: flex;
  align-items: center; /* "height" along primary axis */
  justify-content: right; /* alignment on the primary axis */
  padding: 0.3em; /* Space between menu buttons */
}
.menu-button-container svg {
  height: 48px;
  width: 48px;
}
