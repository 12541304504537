/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  width: 2.5em;
  height: 3.5em;
  border: 1px solid var(--fg-color-greyed);
  border-radius: var(--radius-corner-std);
  display: grid;
  align-items: center;
  justify-items: center;
}

.number {
  font-size: x-large;
}
