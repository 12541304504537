/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  width: 100%;
  height: 100%;
  padding: 0 24px 0 24px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  row-gap: 24px;
}

.input {
  padding: 6px 14px;
}

.result-container {
  grid-row: 2;
  grid-column: 1;
}
.inner-result-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.6em 0 0.6em 0;
  gap: 1em;
  overflow-y: scroll;
}
.result {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  column-gap: 0.8em;
  cursor: pointer;
}

.icon {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  padding: 2px;
  background-color: var(--bg-color-dark2);
  fill: white;
}

.result-text {
  font-size: large;
  margin-top: auto;
  margin-bottom: auto;
}
