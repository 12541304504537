/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  grid-column: 1;
  grid-row: 1;
  display: grid;
  min-height: 0;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  background-color: var(--bg-color-dark1);
  column-gap: 0.2em;
  padding-right: 0.4em;
  border-right: 3px dashed var(--fg-color-dark3);
}
