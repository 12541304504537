/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr) auto;
}

.topbar {
  grid-column: 1;
  grid-row: 1;
}

.popup-page {
  grid-column: 1;
  grid-row: 1/3;

  display: grid;
  grid-template-rows: minmax(0, 1fr);
  background-color: var(--bg-color-dark1);
}
/* If the height is very small (e.g. due to an onscreen keyboard on a mobile phone), the page shall stretch over the complete area */
@media only screen and (max-height: 560px) {
  .popup-page {
    grid-row: 1/4;
  }
}

.popup-page-long {
  grid-row: 1/4;
}

.content {
  grid-column: 1;
  grid-row: 2;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);

  position: relative;
}

.content-tabs {
  grid-column: 1;
  grid-row: 3;
  width: calc(100% - 15%);
  border-top: var(--outline-thickness-thin) #fff3 solid;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 70px;
  /*background-color: var(--card-bg);*/
}

@media only screen and (min-width: 600px) {
  .content-tabs {
    display: none;
  }
}

.content-tab {
  width: 100%;
  text-align: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.content-tab:first-child {
  text-align: left;
}
.content-tab:last-child {
  text-align: right;
}

.content-tab-active {
  font-weight: bold;
}

.content-tab-active svg {
  fill: var(--fg-color-frame);
}

.list-container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
}

.undo-info-toast {
  position: absolute;
  z-index: 3;
  bottom: 55px;
  left: var(--padding-std);
  right: var(--padding-std);
}
