/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.svg-button {
  border: 0;
  padding: 1px 2px 1px 2px;
  background: transparent;
}

.svg-button svg {
}
