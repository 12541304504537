/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.pie-chart {
  aspect-ratio: 1;

  position: relative;

  display: inline-grid;

  place-content: center;
}

.pie-chart:before {
  content: '';
  position: absolute;
  border-radius: 50%;

  inset: 0pt;
  background: conic-gradient(var(--bgcolor) 100%, #0000 0);
}

.pie-chart:after {
  content: '';
  position: absolute;
  border-radius: 50%;

  inset: var(--pie-border);
  background: conic-gradient(var(--fgcolor) calc(var(--p) * 1%), #0000 0);
}
