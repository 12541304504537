.input-panel {
  grid-area: input-panel;

  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;

  grid-template-areas:
    'status status status'
    'secondarybuttons input mainbuttons';

  align-items: center;

  width: 100%;

  background-color: var(--bg-color-dark1);

  box-sizing: border-box;
  padding: 0px 10px 8px 15px;

  font-size: large;
}

.input-panel-status {
  grid-area: status;
  text-align: center;
  width: 100%;
}

.input-panel-status-warning {
  background-color: orange;
  border-radius: 5px;
  color: black;
  width: 100%;
}

.input-control {
  grid-area: input;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
}

.input-panel-buttons {
  grid-area: secondarybuttons;
  height: 32px;
  display: flex;
  align-items: center; /* "height" along primary axis */
  justify-content: left; /* alignment on the primary axis */
}
.input-panel-buttons * {
  height: 100%;
}

.input-panel-mainbuttons {
  grid-area: mainbuttons;
  height: 32px;
  display: flex;
  align-items: center; /* "height" along primary axis */
  justify-content: left; /* alignment on the primary axis */
}
.input-panel-mainbuttons * {
  height: 100%;
  width: auto;
  padding: auto;
}
