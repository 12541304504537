.value {
  display: flex;
  align-items: baseline; /* Ensures proper vertical alignment of numbers and units */
  font-size: 1rem; /* Adjust as needed */
  font-weight: 400; /* Adjust for weight */
}

.value-number {
  font-size: 1.5rem; /* Larger size for the number */
  font-weight: 700; /* Bold for emphasis */
  margin-right: 0.25rem; /* Space between the number and unit */
}

.value-unit {
  font-size: 1rem; /* Same font size as the base container */
  font-weight: 400; /* Normal weight */
}

.value-separate-unit {
  font-size: 0.875rem; /* Slightly smaller for separate unit */
  margin-left: 0.5rem; /* Space between the unit and separate unit */
}

.row {
  display: flex;
  align-items: center; /* Center items vertically */
  gap: 0.5rem; /* Space between items */
  justify-content: space-between; /* Distribute items evenly */
}
