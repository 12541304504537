.button {
  background-color: var(--bg-color-dark2);
  color: var(--text-color);

  border: none;
  border-radius: 999px; /* Make round buttons left and right (use just a big value, its clipped to the height)*/

  box-sizing: border-box;

  display: inline-flex;
  justify-content: center; /* center the content horizontally */
  align-items: center; /* center the content vertically */

  padding: var(--padding-std);
}
