/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color-dark1);
  padding: 2.5em;
}

.icon {
  margin-top: auto;
  width: 64px;
  height: 64px;
  fill: var(--fg-color-frame);
}

.description {
  color: var(--fg-color-bright);
  line-height: var(--line-height-desc);
  margin-bottom: 0.6em;
  margin-top: 1.8em;
}

.assistance {
  color: var(--fg-color-frame);
  line-height: var(--line-height-desc);
  margin-bottom: 0.6em;
}

.instruction {
  color: var(--fg-color-bright);
  line-height: var(--line-height-desc);
  margin-bottom: 2em;
}

.bottom {
  margin-bottom: auto;
}
