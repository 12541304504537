.description {
  font-size: var(--small-text);
}

.description-alone {
  font-size: var(--medium-text);
  font-weight: var(--text-weight);
}

.title {
  font-size: var(--large-text);
  font-weight: var(--text-weight);
}
