.h1 {
  font-size: 2.5rem; /* 40px */
  font-weight: 700; /* Bold */
}

.h2 {
  font-size: 2rem; /* 32px */
  font-weight: 600; /* Semi-bold */
}

.h3 {
  font-size: 1.75rem; /* 28px */
  font-weight: 600; /* Semi-bold */
}

.h4 {
  font-size: 1.5rem; /* 24px */
  font-weight: 600; /* Semi-bold */
}

.h5 {
  font-size: 1.25rem; /* 20px */
  font-weight: 500; /* Medium */
}

.h6 {
  font-size: 1rem; /* 16px */
  font-weight: 500; /* Medium */
}

.h7 {
  font-size: 0.875rem; /* 14px */
  font-weight: 500; /* Medium */
  line-height: 1.6; /* 22.4px line height */
}

.p {
  margin: 0;
  padding: 0;
  font-weight: 400; /* Regular */
}

.primary {
  color: var(--fg-color-frame);
}

.secondary {
  color: var(--fg-color-greyed);
}

.card-secondary {
  color: rgba(81, 83, 94, 1);
  font-size: 10.5px;
  font-weight: 500;
}

.monoSpace {
  font-family: 'Lekton', monospace;
}

.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.error {
  color: red;
}
