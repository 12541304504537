:root {
  --gray: #d7d8d880;
}

.container {
  display: flex;
  flex-direction: column;
}

.circle,
.circle-stopped {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
}

.circle {
  border: 4px solid var(--gray);
}

.circle-stopped {
  border: 4px solid transparent;
  padding: 5px;
  width: 45px;
  height: 45px;
}

.circle-start {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  border: 4px solid #26344d;
  padding: 5px;
  width: 40px;
  height: 40px;
}

.arrow,
.arrow-stopped {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid;
  top: 40%;
  z-index: 20;
  right: -4px;
  transform: translateY(-50%) rotate(-35deg);
}

.arrow {
  border-top-color: var(--gray);
}

.arrow-stopped {
  border-top-color: #26344d;
  right: -6px;
}

.stopped-container {
  position: relative;
  width: 40px;
  height: 40px;
}

.running-container {
  position: relative;
  width: 40px;
  height: 40px;
}

.close {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.shiny {
  position: relative;
  border: none;
  border-radius: 100%;
  overflow: hidden;
}

.shiny::after {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  content: '';
  height: 50%;
  width: 8px;
  background: rgba(255, 255, 255, 0.8);
  filter: blur(4px);
  transform: translateX(20px) translateY(-2px) rotate(0deg);
  animation: shine 6s infinite linear;
  transform-origin: bottom;
}

@keyframes shine {
  50% {
    transform: translateX(20px) translateY(0px) rotate(340deg);
    opacity: 1;
  }
  50.001%,
  100% {
    transform: translateX(20px) translateY(0px) rotate(360deg);
    opacity: 0;
  }
}

.circle-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #0b132b;
  z-index: 10;
  border-radius: 50%;
  padding: 5px;
}

.rect {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}
