/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.confirm-iframe {
  position: absolute;
  left: 0%;
  top: 50px;
  width: 100%;
  height: 70%;
  background-color: var(--bg-color-dark1);
  border-style: none;
  overflow: hidden;
}
