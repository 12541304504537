.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title {
  margin-left: 10px;
}

.cards-container {
  display: flex;
  height: 100%;
}
