/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.title {
  font-size: x-large;
  font-weight: bold;
  margin: 0 1.5em 0 1.5em;
}
