.e-wrapper {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  padding: 2px 10px;
  border-radius: 20px;
  left: 50%;
  transform: translate(-50%, -50%); /* Adjust for horizontal centering and push down */
  font-size: 11px;
  white-space: nowrap;
  color: var(--bg-color-dark1);
  font-weight: 500;
  min-width: 70%;
  height: 18px;
}
