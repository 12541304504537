/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  overflow-y: hidden;
}

.phone-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-auto-columns: 25%;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
}

.cancel {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #000000b0;
  border-radius: 40px;
}
