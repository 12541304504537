.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--fg-color-dark1);
  padding-top: 6px;
  display: flex;
  flex-direction: column;
  z-index: 9999;
}

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.body {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  padding-left: 1em;
  padding-right: 1em;
}
