.info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.name {
  font-size: 13px;
  color: var(--fg-color-bright);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /*background-color: #42ba97;*/
}

.name-medium {
  font-size: 12px;
  color: var(--fg-color-bright);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /*background-color: #42ba97;*/
}

.name-small {
  font-size: 10px;
  color: var(--fg-color-bright);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /*background-color: #42ba97;*/
}

.birthday-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 4px;
  font-size: 13px;
}

.birthday {
  color: var(--fg-color-greyed);
  font-size: 13px;
  position: relative;
}

.chips-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 5px;
  overflow: hidden;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-weight: 500;
}

.blocked-bed {
  display: flex;
  flex-direction: column;
}

.center-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 100%;
  width: 100%;
  font-weight: 500;
  color: #51535e;
}

.blocked-bed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-weight: 500;
  color: #51535e;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
}

.container-with-gap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
