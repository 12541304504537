/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.image {
  border-radius: 999em;
}
