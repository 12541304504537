/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  margin: 10px 25px;
}

.inner-container {
  display: flex;
  flex-direction: column;
  padding: 15px 4px;
}

.group-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 6px 10px;
  gap: 10px;
}
.group {
  font-size: 16pt;
}
.next-group {
  border-top: 1px solid var(--fg-color-greyed-dark);
}

.option-container {
  display: flex;
  flex-direction: column;
  margin: 4px 4px 4px 16px;
}

.option {
  font-size: large;
  margin: 8px;
}
.selected {
  color: var(--fg-color-warning);
}
