/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.icon {
  fill: var(--fg-color-bright);
}
