/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.image-button {
  border: 0;
  background: transparent;
}
