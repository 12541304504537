.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: var(--card-bg);
  padding: 10px;
  border-radius: 16px;
  width: 100%;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
