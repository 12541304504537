.conversation-frame {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'thread'
    'input-panel';
  position: relative;
}

.thread-area {
  grid-area: thread;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
}

.thread-item-filter {
  position: absolute;
  top: 5pt;
  right: 5pt;
  width: auto;
  height: auto;

  border: solid 1pt var(--bg-color-bright);
  border-radius: var(--radius-corner-std);

  background-color: var(--bg-color-dark1);
  opacity: 0.8;
  z-index: 1;
}

.input-proposal-selection {
  grid-area: thread;

  background: rgba(var(--bg-color-dark1-rgb), 0.8);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  border: 1pt solid var(--bg-color-dark1);
  border-radius: var(--radius-corner-small);

  padding-left: var(--padding-small);
  padding-right: var(--padding-small);

  /* height: 80vh; */ /* Limit the height to ~80% of viewport height, so that in case the keyboard is open, the scrollbar becomes visible*/
  overflow-y: auto;

  color: var(--fg-color-frame);
  font-size: large;

  display: flex;
  flex-direction: column-reverse;
  z-index: 1;
}
