/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  font-size: x-large;
  font-weight: bold;
  margin: auto;
}

.line1 {
  color: var(--fg-color-frame);
}
