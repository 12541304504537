.close-button {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 10px;
}

.image {
  width: 100%;
}
