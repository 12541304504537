.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-wrap: nowrap;
}

.line {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  border-radius: 10px;
}

.tab {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 5px;
}
