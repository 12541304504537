/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.button {
  background-color: var(--fg-color-frame);
  color: var(--fg-color-bright);
  font-size: medium;
  font-weight: bold;
  border: none;
  width: 100%;
  padding: var(--padding-std);
  border-radius: 10em;
  overflow: hidden;
}
.button-secondary {
  background: none;
  color: var(--fg-color-frame);
  border: 1pt solid var(--fg-color-frame);
  font-size: medium;
  font-weight: bold;
  width: 100%;
  padding: var(--padding-std);
  border-radius: 10em;
  overflow: hidden;
}

.button-tertiary {
  background-color: rgba(255, 255, 255, 0.04);
  color: var(--fg-color-bright);
  font-size: medium;
  font-weight: bold;
  border: none;
  width: 100%;
  padding: var(--padding-std);
  border-radius: 10em;
  overflow: hidden;
}

.button:disabled {
  background-color: var(--fg-color-dark3);
  color: var(--fg-color-greyed);
}
.button:active {
  background-color: var(--fg-color-dark3);
}
.button-secondary:disabled {
  background-color: var(--fg-color-dark3);
  color: var(--fg-color-greyed);
  border: 1pt solid var(--fg-color-dark3);
}
.button-secondary:active {
  background-color: var(--fg-color-dark3);
  color: var(--fg-color-bright);
  border: 1pt solid var(--fg-color-dark3);
}

.logo {
  height: 100%;
}

.animation-container {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: 5px;
  left: 5px;
  bottom: 5px;
  right: 5px;
  background: #090f23;
  flex: 1;
  padding-right: 10px;
  border-radius: 50px;
}
