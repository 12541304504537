.container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-radius: 8px 0px 0px 0px;
}

.all {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 8px 0px 0px 8px;
}
