/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5em;
}
.highlight {
  color: var(--fg-color-frame);
}
.challenge {
  color: var(--fg-color-warning);
}
