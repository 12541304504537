/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.outer-container {
  display: flex;
  flex-direction: column;
}

.inner-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.2em;
}

.todo-icon {
  stroke: var(--fg-color-bright);
  flex-shrink: 0;
}
.checkbox-icon {
  stroke: var(--fg-color-frame);
  flex-shrink: 0;
}
.checkbox-problem-icon {
  stroke: var(--fg-color-warning);
  flex-shrink: 0;
}
.problem-icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  stroke: var(--fg-color-warning);
  fill: var(--fg-color-warning);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  flex-grow: 1;
}

.header {
  font-size: medium;
}

.text-more-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.text-container {
  display: inline;
}
.shallow-description {
  color: var(--fg-color-greyed);
  padding-right: 0.5em;
}
.description {
  padding-right: 0.5em;
}
.problem-description {
  color: var(--fg-color-warning);
  padding-right: 0.5em;
}
.positive {
  color: var(--fg-color-frame);
}
.negative {
  color: var(--fg-color-warning);
}
.options-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.comment-text {
  font-size: small;
  color: var(--fg-color-greyed);
}
.comment-button {
  flex-shrink: 0;
  background-color: transparent;
  border: none;
  transform: translateY(3px);
}
.comment-icon {
  fill: var(--fg-color-frame);
}
.camera-icon {
  fill: var(--fg-color-frame);
  flex-shrink: 0;
  background-color: transparent;
  border: none;
}
.disabled-camera-icon {
  width: 0px;
  background-color: transparent;
  border: none;
}
.image-container {
  flex-shrink: 0;
}
.image {
  width: 64px;
  height: 64px;
}
.image-close {
  position: absolute;
  top: -3px;
  right: 5px;
  border: 1px solid var(--bg-color-frame);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--bg-color-dark1);
}
.button-container {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.scroll-element {
  font-size: 2pt;
}
