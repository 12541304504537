/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.user-info {
  display: flex;
  flex-direction: row;
  gap: var(--padding-std);
  align-items: center;
}

.user-name {
  display: inline;
}

.user-image {
  box-sizing: border-box;
  height: 100%;
  padding: 4px;
}
