/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.main-frame {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.children {
  height: 100%;
}
