.or-items {
  position: relative;
  margin: 0 5px;
}

.or-items-outer-test {
  grid-row: 1;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.blocked-saal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background: repeating-linear-gradient(
    -45deg,
    rgba(18, 23, 46, 0.3),
    rgba(18, 23, 46, 0.3) 10px,
    rgba(48, 56, 89, 0.3) 10px,
    rgba(48, 56, 89, 0.3) 20px
  );
  font-size: var(--xlarge-text);
  line-height: 90%;
}

.blocked-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  gap: 10px;
}

.blocked-container {
  margin-top: 71px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}

.pat {
  color: var(--fg-color-warning);
  text-align: center;
  font-size: 12px;
}

.reason {
  color: var(--fg-color-greyed);
  text-align: center;
  font-size: 12px;
}
