/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.main-info-item {
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  width: calc(100% - 1em);

  display: grid;
  grid-template-columns: 20fr 80fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'symbol message'
    'actions actions';
}
.main-info-item button:first-child {
  margin-top: 0em;
}

.symbol {
  grid-area: symbol;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.symbol-escalation {
  width: 48px;
  height: auto;
}
.symbol-info {
  width: 48px;
  height: auto;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content p {
  margin-top: 1em;
  margin-bottom: 0;
}

.sender {
  font-weight: bold;
  color: #42ba97;
  margin-bottom: 0.25em;
}

.response {
  grid-area: actions;
  margin: 1em;
  width: calc(100% - 2em);
}

.inquiry {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.countdown {
  margin-top: 4px;
}
