/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.selections {
  /* no space between input box and suggestions */
  border-bottom-width: 0;
  margin-top: 0;
  margin-bottom: 0;
  /* no bullets */
  list-style: none;

  /* reverse padding of standard <li> items */
  padding: 0;
}

.selections li {
  padding: 0.4em 0.3em 0.4em 0.3em;
  color: var(--fg-color-bright);
}

.selections .selection-item-active,
.selections li:hover {
  color: var(--fg-color-frame);
  cursor: pointer;
}

/* Thin lines between items */
.selections li:not(:last-of-type) {
  border-bottom: none;
}

.selections .highlight {
  font-weight: bold;
  color: var(--fg-color-frame);
}

.selection-no-items-hint {
  color: var(--fg-color-greyed);
  font-style: italic;
  padding: 0.4em 0.3em 0.4em 0.3em;
}
