/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  grid-row: 2;
  grid-column: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat {
  padding: 0.5em 0em 0.5em 0em;
  height: 100%;
}
.chat-list {
  padding: 0.5em 0em 0.5em 0em;
}
.workflow-shadow {
  flex: 1;
  height: 100%;
}
.menu {
  grid-row: 1 / 3;
  grid-column: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(0, 1fr);
}

.undo-info-toast {
  position: absolute;
  z-index: 110;
  bottom: 55px;
  left: var(--padding-std);
  right: var(--padding-std);
}

.scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
