.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.specialty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 8px;
}

.specialty-content {
  padding: 0;
  margin: 0;
}

/* White */
.specialty-white {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.9);
}

/* Green */
.specialty-green {
  color: rgba(69, 179, 46, 1);
  background-color: rgba(69, 179, 46, 0.15);
}

/* Yellow */
.specialty-yellow {
  color: rgba(207, 192, 52, 1);
  background-color: rgba(207, 192, 52, 0.15);
}

/* Orange */
.specialty-orange {
  color: rgba(224, 124, 60, 1);
  background-color: rgba(224, 124, 60, 0.15);
}

/* Purple */
.specialty-purple {
  color: rgba(231, 70, 231, 0.9);
  background-color: rgba(128, 0, 128, 0.2);
}

/* Violet */
.specialty-violet {
  color: #ad78f1;
  background-color: rgba(173, 120, 241, 0.15);
}

/* Blue */
.specialty-blue {
  color: rgba(88, 166, 255, 1);
  background-color: rgba(88, 166, 255, 0.15);
}

/* Red */
.specialty-red {
  color: rgba(255, 112, 155, 1);
  background-color: rgba(255, 112, 155, 0.15);
}

/* Cyan */
.specialty-cyan {
  color: rgba(0, 255, 255, 0.8);
  background-color: rgba(0, 255, 255, 0.2);
}

.specialty-gray {
  color: rgba(186, 186, 186, 0.8);
  background-color: rgba(186, 186, 186, 0.1);
}
.specialty-salmon {
  color: rgb(250, 128, 114);
  background-color: rgba(250, 128, 114, 0.2);
}
.specialty-brown {
  color: rgb(232, 178, 178);
  background-color: rgba(165, 42, 42, 0.7);
}
.specialty-light-green {
  color: rgb(144, 238, 144);
  background-color: rgba(144, 238, 144, 0.2);
}
.specialty-teal {
  color: rgb(6, 190, 190);
  background-color: rgba(0, 128, 128, 0.2);
}
.specialty-pink {
  color: rgb(255, 192, 203);
  background-color: rgba(255, 192, 203, 0.2);
}
