/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 0;
  overflow-y: auto;
}

.header {
  padding: 1em 2em 0.5em 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 3em;
  margin-right: 3em;
  background: none;
}
